import api from './api'
import { getHeaders } from './user'

export async function listCharges(token) {
  const { data } = await api.get('/charge', { ...getHeaders(token) })
  return data
}

export async function registerCharge(token, values) {
  await api.post('/charge', { ...values }, { ...getHeaders(token) })
}

export async function updateCharge(token, idCharge, values) {
  await api.put(`/charge/${idCharge}`, { ...values }, { ...getHeaders(token) })
}

export async function deleteCharge(token, idCharge) {
  await api.delete(`/charge/${idCharge}`, { ...getHeaders(token) })
}
