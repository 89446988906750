/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { listCategories } from 'services/category'
import { listCharges } from 'services/charge'
import { listCustomers } from 'services/customer'
import { detailUser } from '../services/user'
import { messageError } from '../utils/toast'
import useApi from './useApi'
import useGlobal from './useGlobal'

function useRequestsProvider() {
  const { handleLogout } = useApi()
  const { token, setUser, setCategories, setCustomers, setCharges } = useGlobal()
  const [resetTables, setResetTables] = useState(false)

  async function handleGetUser() {
    try {
      const result = await detailUser(token)
      const { token: _, ...userData } = result
      setUser(userData)
    } catch (error) {
      messageError(error.response.data.message)
      setTimeout(() => {
        handleLogout()
      }, 2000)
    }
  }

  async function handleListCategories() {
    try {
      const result = await listCategories(token)
      setCategories(result)
    } catch (error) {
      messageError()
    }
  }

  async function handleListCustomers() {
    try {
      const result = await listCustomers(token)
      setCustomers(result)
    } catch (error) {
      messageError()
    }
  }

  async function handleListCharges() {
    try {
      const result = await listCharges(token)
      setCharges(result)
    } catch (error) {
      messageError()
    }
  }

  async function handleUpdateTables() {
    try {
      await Promise.all([
        handleListCategories(),
        handleListCustomers(),
        handleListCharges()
      ])
    } catch (error) {
      messageError()
      setResetTables(false)
    } finally {
      setResetTables(false)
    }
  }

  useEffect(() => {
    if (token || resetTables) {
      handleUpdateTables()
    }
  }, [token, resetTables])

  return {
    handleGetUser,
    resetTables: () => setResetTables(true)
  }
}

export default useRequestsProvider
