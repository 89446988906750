import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { useLocalStorage } from 'react-use'
import { theme } from 'theme/index'
import { menuList } from 'utils/menuList'

function useGlobalProvider() {
  const [user, setUser, removeUser] = useLocalStorage('user')
  const [token, setToken, removeToken] = useLocalStorage('token')
  const [openLoading, setOpenLoading] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [stateMenuList, setStateMenuList] = useState([...menuList])
  const upMobile = useMediaQuery(theme.breakpoints.up('sm'))
  const [categories, setCategories] = useState([])
  const [customers, setCustomers] = useState([])
  const [charges, setCharges] = useState([])
  const [chargeType, setChargeType] = useState('inputs')
  const handleChangeTab = (_, value) => setChargeType(value)

  function toggleMobileMenu() {
    setOpenMenu(!openMenu)
  }

  return {
    user,
    setUser,
    removeUser,
    token,
    setToken,
    removeToken,
    openLoading,
    setOpenLoading,
    openMenu,
    setOpenMenu,
    toggleMobileMenu,
    stateMenuList,
    setStateMenuList,
    upMobile,
    categories,
    setCategories,
    customers,
    setCustomers,
    charges,
    setCharges,
    chargeType,
    handleChangeTab
  }
}

export default useGlobalProvider
